header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
  box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.068);
}

header div {
  display: flex;
  flex-direction: column;
}

header p {
  font-size: 14px;
  margin-right: 5vw;
}


/* Hier ist dein CSS Grid Generator Code, anhand der farben von den einzelnen Child elemente, siehst du um welches Child element es sicht handelt. */