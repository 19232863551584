.box {
  width: 100%;
  height: 50px;
  border: 1px solid;
}
.main {
  gap: 3px;
}
.grid-generator {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}
.box {
  border-radius: 8px;
  border: 1px solid white;
  background: rgb(2, 0, 36);
  background: linear-gradient(340deg, rgb(186, 186, 186), rgb(254, 254, 254));
  min-height: 10vh;

  padding: 10px !important;
}
.grid-area {
  grid-area: 1 / 1 / 13 / 10;
  border-right: 3px solid var(--light-gray);
  padding-right: 30px;
}

.input-area {
  grid-area: 1 / 10 / 13 / 13;
}
.selectable-selectbox {
  cursor: crosshair;
  background: none;
  border: 1px dashed orange;
}
