.btn-modal {
  z-index: 10;
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
}

.modal h4 {
  font-size: 3rem;
}
.overlay {
  background: rgba(49, 49, 49, 0.656);
  cursor: pointer;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 500px;
  z-index: 15;
  border-radius: 15px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  cursor: pointer;
}

.output {
  background-color: rgba(0, 0, 0, 0.859);
  padding: 30px;
  border-radius: 15px;
  color: var(--white);
  margin: 30px 0 15px 0;
  max-height: 50vh;
  overflow-y: scroll;
}

.output .last-klammer {
  margin: 0 0 15px 0;
}
.parent {
  color: orange;
}

.output div p {
  margin-left: 15px;
}

.output-childs {
  margin-bottom: 15px;
}

.output-childs span {
  margin: 0 0 0 4px;
}

.operator {
  color: #5ae3b3;
}

.value {
  color: #f89584;
  margin: 0 0 0 5px;
  word-spacing: 3px;
}

.klammern {
  color: var(--white);
}

.btn-copy-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin: 0;
  margin-bottom: 15px;
}

.btn-copy-area button {
  margin: 0;
}

.btn-copy-area p {
  color: green;
  font-weight: bold;
}
