/* Add Fonts */

@font-face {
  font-family: "Cario";
  src: local("Cario"), url("./assets/font/Cairo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "CarioBold";
  src: local("Cario"), url("./assets/font/Cairo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CarioSemiBold";
  src: local("Cario"),
    url("./assets/font/Cairo-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "CarioLight";
  src: local("Cario"), url("./assets/font/Cairo-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoMono";
  src: local("Cario"),
    url("./assets/font/RobotoMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoMonoBold";
  src: local("Cario"),
    url("./assets/font/RobotoMono-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoMonoLight";
  src: local("Cario"),
    url("./assets/font/RobotoMono-Light.ttf") format("truetype");
}

/* Basic Styles */

:root {
  --main-color: #6418c3;
  --secondary-color: #5ecfff;
  --white: #ffffff;
  --text-black: #202020;
  --text-light: #a5a5a5;
  --bg-black: #3e3e3e;
  --light-gray: #cecece;
  --hover: #6518c396;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f9f9f9;
}

html {
  font-size: 62.5%;
}

h1 {
  font-family: CarioBold, Arial, Helvetica, sans-serif;
  font-size: clamp(2.4rem, 2.8vw, 3.2rem);
}
h2 {
  font-family: CarioBold, Arial, Helvetica, sans-serif;
  font-size: clamp(1.8rem, 4.5vw, 4.8rem);
}
h3 {
  font-family: CarioSemiBold, Arial, Helvetica, sans-serif;
  font-size: clamp(1.6rem, 1.7vw, 1.6rem);
}
h4 {
  font-family: CarioBold, Arial, Helvetica, sans-serif;
  font-size: clamp(1.7rem, 3.8vw, 4.2rem);
}
h5 {
  font-family: CarioSemiBold, Arial, Helvetica, sans-serif;
  font-size: clamp(1.6rem, 3.6vw, 4rem);
}
h6 {
  font-family: CarioSemiBold, Arial, Helvetica, sans-serif;
  font-size: clamp(1.6rem, 3.4vw, 3.8rem);
}
p {
  font-family: Cario, Arial, Helvetica, sans-serif;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
}
a {
  text-decoration: none;
  color: var(--text-black);
  font-size: clamp(1.4rem, 2vw, 1.6rem);
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--text-light);
  font-size: 12px;
}

input {
  margin-bottom: 15px;
  border: 1px solid var(--text-light);
  padding: 10px 15px;
  border-radius: 10px;
  width: 100%;
}

input:focus {
  border-color: var(--main-color);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
span {
  font-weight: bold;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh;
}

header {
  grid-area: 1 / 3 / 2 / 13;
}

.content {
  margin: 30px;
  grid-area: 2 / 3 / 13 / 13;
  background-color: var(--white);
  padding: 30px;
  border-radius: 30px;
  overflow-y: scroll;
}

button {
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  background: none;
  min-width: 120px;
  margin: 0 0 15px 0;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s;
}
.btn-full {
  background-color: var(--main-color);
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
}

.btn-full:hover {
  background-color: var(--secondary-color);
  transition: all 0.3s;
}
.btn-border {
  border: 2px solid var(--main-color);
  color: var(--main-color);
  font-weight: bold;
  text-transform: uppercase;
}

.btn-border svg {
  stroke: var(--main-color);
  height: 17px;
  width: auto;
  transition: all 0.3s;
}

.btn-border:hover {
  border: 2px solid red;
  /* background-color: var(--secondary-color); */
  color: red;
  transition: all 0.3s;
}

.btn-border:hover svg {
  transition: all 0.3s;
  stroke: red;
}
button img {
  height: 17px;
  width: auto;
}

.shake:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* KLEINER MONITOR */
@media only screen and (max-width: 1350px) {
  .coffee {
    margin: 0 0 1.5rem 0;
  }
  .nav-item {
    margin: 1.5rem 0 1.5rem 0;
  }
}
